import hdfcimg from '../../assets/images/hdfc.svg'
import cellimg from '../../assets/images/cell-phone.png'
import backarrowimg from '../../assets/images/back-arrow.svg'
import logo from '../../assets/images/logo.svg'
import { useEffect, useState } from 'react'
import WebService from '../../utility/WebService'
import { toast } from 'react-toastify'

const FetchDetail = () => {
    const [accounts, setAccounts] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getBankAcount()
    }, [])

    const getBankAcount = () => {
        WebService.getAPI({
            action: `/aaclient/accounts/LINKED`,
            body: null
        })
            .then((res: any) => {
                var array = [];
                for (var i in res.accountVOList) {
                    for (var j in res.accountVOList[i].accounts) {
                        res.accountVOList[i].accounts[j].isChecked = false;
                        array.push(res.accountVOList[i].accounts[j])
                    }
                }
                setAccounts(array)
            })
            .catch((e) => {

            })
    }

    const onAccept = () => {
        var array = [];
        var value = false;
        for (var i in accounts) {
            if (accounts[i].isChecked == true) {
                value = true;
                array.push({ accRefNumber: accounts[i].accRefNumber, fipId: accounts[i].fipId })
            }
        }
        if (value == true) {
            WebService.postAPI({
                action: `/aaclient/Consent/Approved?sessionId=${sessionStorage.getItem('session')}&txnid=${sessionStorage.getItem('txn')}&redirect=${sessionStorage.getItem('redirect')}`,
                body: {
                    ConsentId: "2334b426-5412-4499-882a-3039325f3f02",
                    Accounts: array
                }
            })
                .then((res: any) => {
                    window.open(
                        res.redirectUrl, "_blank");
                })
                .catch((e) => {

                })
        } else {
            toast.error('Please select at least one account.')
        }

    }

    const onDeny = () => {
        setLoading(true)
        WebService.getAPI({
            action: `/aaclient/Consent/c9401450-a59c-4ea1-be29-ed404a55c5ac/REJECTED?sessionId=4501ed7a-86e6-4a31-88e4-634052eb06ce&redirect=https://fiu-ui-uat.omsaa.com/%23&txnid=b6353614-0655-4196-b47c-e711e2bb7663`,
            body: null
        })
            .then((res) => {
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    return (
        <>
            <section className="mt100 om-linkac">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a href="Select Account" className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Link Account</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-4">
                            <h4 className="d-none d-sm-block">Fetch my bank details securely! <span style={{ color: '#ccc', fontSize: '12px', marginLeft: '22px' }} onClick={() => onDeny()}> Deny</span></h4>
                            <p className="om-found-text">We found the below bank account(s) linked to your mobile number +91-{sessionStorage.getItem('mobile')}. Please select the bank accounts that you want to leave.</p>
                            <div className='om-link-wraaper'>
                                <div className="row">

                                    <div className='col-sm-12'>
                                        <p>Linked Account</p>
                                        {
                                            accounts.length > 0 && accounts.map((res, index) => {
                                                return (
                                                    <div className='om-slect-box om-slect-box-selected'>
                                                        <div className="om-link-box">
                                                            <div><img src={hdfcimg} alt="icon" /></div>
                                                            <div>
                                                                <h5>{res.accType} Account</h5>
                                                                <h6>{res.maskedAccNumber}</h6>
                                                                <p>{res.fipId}</p>
                                                            </div>
                                                        </div>
                                                        <div className='om-link-box-rt text-right'>
                                                            {/* <i className="fa fa-check-circle" style={{ color: '#1542b0' }} aria-hidden="true"></i> */}
                                                            <input type="checkbox" checked={res.isChecked} onClick={() => {
                                                                accounts[index].isChecked = !accounts[index].isChecked
                                                                setAccounts([...accounts])
                                                            }} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            accounts.length == 0 && <div className='text-center'>
                                                No Accounts Found!
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className='col-lg-12 col-sm-6 mx-auto my-auto pt-4 '>
                                    <div className='om-notfindac'>
                                        <h3>Couldn't find your account?</h3>
                                        <div className='d-flex om-warning-text'>
                                            <span><img className='mr-2' src={cellimg} alt="icon" /></span>
                                            <div>
                                                <p>In case you have a savings account linked to secondary mobile no. Please Modify your mobile number </p>
                                                <p><a href="/modify-mobilenum">Modify mobile number</a></p>
                                            </div>
                                        </div>

                                        <p className='om-ac-warning'>We currently don't support joint and current accounts.</p>
                                        <div className='om-btn' onClick={() => onAccept()}>Accept</div>
                                        <p className='om-copyright-text'>Powered by RBI regulated Account Aggregator <img src={logo} alt="" width="30" height="30" /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default FetchDetail;