import { Routes, Route } from "react-router-dom";
import AdditionalInformation from "../Screens/AdditionalInformation/AdditionalInformation";
import FetchDetail from "../Screens/FetchDetail/FetchDetail";
import LinkAccount from "../Screens/LinkAccount/LinkAccount";
import ModifyMobileNumber from "../Screens/ModifyMobileNumber/ModifyMobileNumber";
import SelectAccount from "../Screens/SelectAccount/SelectAccount";
import Signup from "../Screens/Signup/Signup";
import Successful from "../Screens/Successful/Successful";
import VerifyOtp from "../Screens/VerifyOtp/VerifyOtp";

const Navigation = () => {
    return (
        <>
            <div>
                <Routes>
                    <Route path="/" element={<SelectAccount />} />
                    <Route path="/select-account" element={<SelectAccount />} />
                    <Route path="/verify-otp" element={<VerifyOtp />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/additional-information" element={<AdditionalInformation />} />
                    <Route path="/link-account" element={<LinkAccount />} />
                    <Route path="/fetch-detail" element={<FetchDetail />} />
                    <Route path="/modify-mobilenum" element={<ModifyMobileNumber />} />
                    <Route path="/successful" element={<Successful />} />
                </Routes>
            </div>
        </>
    )
}

export default Navigation;