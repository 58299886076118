import React, { useState, useEffect } from 'react';
import userIcon from '../../assets/images/user-icon.svg';
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Label } from '../../components/Label/Label';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import WebService from '../../utility/WebService';
import { toast } from 'react-toastify';

const AdditionalInformation = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue, control } = useForm();
    let location = useLocation();
    let history = useNavigate();
    const [information, setInformation] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (location.state != null) {
            setInformation(location.state.details)
        }
    }, [location]);


    const onProceed = (data: any) => {
        for (var i in information) {
            if (information[i].name == 'aadhar' && data.aadhar) {
                information[i].value = data.aadhar
            } else if (information[i].name == 'pan' && data.pan) {
                information[i].value = data.pan
            } else if (information[i].name == 'email' && data.email) {
                information[i].value = data.email
            } else if (information[i].name == 'ppan' && data.ppan) {
                information[i].value = data.ppan
            } else if (information[i].name == 'accNo' && data.account) {
                information[i].value = data.account
            } else if (information[i].name == 'dob' && data.dob) {
                information[i].value = data.dob
            } else if (information[i].name == 'crn' && data.crn) {
                information[i].value = data.crn
            }
        }
        setLoading(true)
        WebService.postAPI({
            action: `/aaclient/accounts/search`,
            body: {
                fipEntityid: 'OMS_FIP',
                information: information
            }
        })
            .then((res: any) => {
                setLoading(false)
                history("/link-account", { state: { data: res.accounts, name: location.state.data.name, logo: location.state.data.logo } });
            })
            .catch((e) => {
                setLoading(false)
                toast.error(e?.response?.data?.errorMsg)
            })
    }


    return (
        <>
            <section className="mt100">
                <div className="container h-100">
                    <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                        <h4 className="d-none d-sm-block my-4">Additional Information</h4>
                        <form className="mt-3" onSubmit={handleSubmit(onProceed)}>
                            {
                                information.length > 0 && information.map((res) => {
                                    if (res.name == 'aadhar' && res.value == null) {
                                        return <div className="form-group">
                                            <label>Aadhar Number</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Aadhar Number" {...register("aadhar", { required: true })}
                                                />
                                            </div>
                                            {errors.aadhar && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter aadhar number"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'pan' && res.value == null) {
                                        return <div className="form-group">
                                            <label>PAN Number</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Pan Number" {...register("pan", { required: true })}
                                                />
                                            </div>
                                            {errors.pan && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter PAN number"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'email' && res.value == null) {
                                        return <div className="form-group">
                                            <label>Email</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Email" {...register("email", { required: true })}
                                                />
                                            </div>
                                            {errors.email && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter email address"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'ppan' && res.value == null) {
                                        return <div className="form-group">
                                            <label>PPAN Number</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="PPAN Number" {...register("ppan", { required: true })}
                                                />
                                            </div>
                                            {errors.ppan && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter PPAN number"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'accNo' && res.value == null) {
                                        return <div className="form-group">
                                            <label>Acc. Number</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Acc. Number" {...register("account", { required: true })}
                                                />
                                            </div>
                                            {errors.account && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter account number"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'dob' && res.value == null) {
                                        return <div className="form-group">
                                            <label>Date of Birth</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <div style={{ width: '92%' }}>
                                                    <Controller
                                                        name='dob'
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field }) => {
                                                            return (
                                                                <DatePicker
                                                                    selected={field.value}
                                                                    placeholderText="Date of Birth"
                                                                    className="form-control"
                                                                    maxDate={new Date()}
                                                                    onChange={(data: any) =>
                                                                        field.onChange(data)
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {errors.dob && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter Date of Birth"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    } else if (res.name == 'crn' && res.value == null) {
                                        return <div className="form-group">
                                            <label>CRN Number</label>
                                            <div className="input-group input-group-lg">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src={userIcon} alt="User" />
                                                    </span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="CRN Number" {...register("crn", { required: true })}
                                                />
                                            </div>
                                            {errors.crn && (
                                                <div style={{ marginLeft: 60 }}>
                                                    <Label title={"Please enter CRN number"} modeError={true} />
                                                </div>
                                            )}
                                        </div>
                                    }
                                })
                            }
                            <div className="form-group mt-4">
                                <button type="submit" name="" value="Create Account" className="btns" >
                                    {isLoading && <div className='om-btn-loader-m'></div>}Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdditionalInformation;