const HelperService = {
    allowOnlyNumericValue(e: any) {
        var numbers = /^[0-9]$/;
        if (!e.key.match(numbers) && e.keyCode != 8) {
            e.preventDefault();
            return false;
        }

        if (e.currentTarget.value.length > 11) {
            e.preventDefault();
            return false;
        }
    },

    getTitleCase(value: any) {
        if (!value) {
            return ""
        } else {
            var str = ''
            var arr = value.split('_')
            for (var i in arr) {
                if (Number(i) == 0) {
                    str = arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                } else {
                    str += '' + arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                }
            }
            return str
        }
    }
}

export default HelperService;