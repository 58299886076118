import { useEffect, useState } from "react";
import hdfcimg from '../../assets/images/hdfc.svg'
import cellimg from '../../assets/images/cell-phone.png'
import backarrowimg from '../../assets/images/back-arrow.svg'
import logo from '../../assets/images/logo.svg'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModifyMobileNumber = () => {

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);


    return (
        <>
            <section className="mt100 om-modify-mobile-warpper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a href="Select Account" className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Link Account</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-4">
                            <h4 className="d-none d-sm-block">Modify Mobile No.</h4>
                            <p className="om-found-text">Please modify your mobile number linked to HDFC Bank. This mobile number will be used to fetch your bank
                             account.</p>
                             <div className='om-mobile-mdify-enter'>
                             <p>Mobile number</p>
                              <input type="number" placeholder='91' />
                             </div>

                            <div className='om-link-wraaper'>
                                <div className='col-lg-6 col-sm-6 mx-auto my-auto pt-4 position-fixed fixed-bottom'>
                                    <div className='om-notfindac'>
                                        <a onClick={() => setShow(true)}  className='om-btn'>Proceed</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                
            </section>

           {/* Terminate pop up */}
            <Modal className="om-terminate" show={show} onHide={handleClose}>
                
                <Modal.Body>
                    
                    <div className='om-terminate-wrapper'>
                    <p>Are you sure you want to terminate your linking process</p>
                    <a  href="" className='om-btn om-btn-yes'>Yes , Go Back</a>
                    <a  href="" className='om-btn'>No , Continue</a>
                    </div>

                </Modal.Body>
                
            </Modal>

            {/* Terminate pop up */}  
            
        </>
    )
}
export default ModifyMobileNumber;



  
    
