import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import nextarrwoimg from '../../assets/images/next-arrow.svg'
import backarrowimg from '../../assets/images/back-arrow.svg'
import otheriocn from '../../assets/images/otherbank-icon.png'
import logo from '../../assets/images/logo.svg'
import WebService, { BASE_URL } from "../../utility/WebService";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const SelectAccount = () => {
    const [show, setShow] = useState(false);
    const [banks, setBanks] = useState<any[]>([])
    let navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const payload = urlParams.get('payload');
    const fi = urlParams.get('fi');
    const requestorType = urlParams.get('requestorType');
    const reqDate = urlParams.get('reqDate');

    const handleClose = () => setShow(false);

    useEffect(() => {
        getPayload()
    }, [])

    const getPayload = () => {
        axios.get(`${BASE_URL}/aaclient/Redirect/Url/Request?payload=${payload}&fi=${fi}&requestorType=${requestorType}&reqDate=${reqDate}`)
            .then((res: any) => {
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('mobile', res.data.mobile)
                sessionStorage.setItem('redirect', res.data.redirect)
                sessionStorage.setItem('txn', res.data.txnId)
                sessionStorage.setItem('session', res.data.sessionId)
                sessionStorage.setItem('consent', res.data.consentId)
                getBanks(res.data.fipIdList)
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
            })
    }

    const getBanks = (data: any) => {
        WebService.postAPI({
            action: `/aaclient/fip/search`,
            body: {
                fipIdList: data
            }
        })
            .then((res: any) => {
                setBanks(res.fipList)
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
            })
    }

    const onCheck = (data: any) => {
        navigate("/verify-otp");
    }

    return (
        <>
            <section className="mt20 om-selectac">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a href="javascript:;" className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Select Account</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-4">
                            <h4 className="d-none d-sm-block">Select Account</h4>
                            <div className="">
                                {
                                    banks.map((res) => {
                                        return (
                                            <div className="white-row pointer" onClick={() => onCheck(res)}>
                                                <span className='om-ac-name'> <img className='mr-2' src={res.logo} alt="icon" height={40} width={40} /> {res?.name}   </span>
                                                <div className="pull-right mn-con-icon pt-1">
                                                    <span className=""></span>
                                                    <img src={nextarrwoimg} alt="" style={{ marginTop: 10 }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='om-bottom-wrapper'>
                                    <div className='om-grey-text'><p>Powerded by RBI regulated Account Aggregator</p></div>
                                    <div><img src={logo} alt="" width="70" height="70" /></div>
                                    <p className='om-single-text'><a href='#'>How it Works</a></p>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section>

            {/* Other pop up */}
            <Modal className="om-other" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className='om-other-wrapper'>
                        <h2>Other banks Coming Soon</h2>

                        <img src={otheriocn} alt="icon" />
                        <p>Only 5 bank have joined account aggregator ecosystem. Other bank will be added soon</p>
                        <a href="" className='om-btn'>Go to Dashboard</a>

                    </div>
                </Modal.Body>
            </Modal>

            {/* Other pop up */}

        </>
    )
}

export default SelectAccount;