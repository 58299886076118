import backarrowimg from '../../assets/images/back-arrow.svg'
import hdfcimg from '../../assets/images/hdfc.svg'
import cellimg from '../../assets/images/cell-phone.png'
import reqdataimg from '../../assets/images/requested-data.png'
import recdataimg from '../../assets/images/data-received.png'
import React, { useEffect } from 'react';
import WebService from '../../utility/WebService';


const Successful = () => {

    useEffect(() => {
        getBanks()
    }, [])

    const getBanks = () => {
        WebService.getAPI({
            action: `/aaclient/fip/search`,
            body: null
        })
    }
    return (
        <>
            <section className="mt100 om-succesfulscreen">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a href="Select Account" className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Link Account</span>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-4">
                            <div className='om-success-wrapper'>
                                <div className='text-center'>
                                    <img src={hdfcimg} alt="icon" />
                                    <h2 className="d-none d-sm-block text-center">Account Successfully Linked</h2>
                                    <div className='om-acc-succsess-box'>
                                        <ul>
                                            <li>
                                                <div className='suceess-img-box'>
                                                    <div className='sucess-imgbox-inner'>
                                                        <img src={reqdataimg} alt='icon' />
                                                    </div>
                                                </div>
                                                <div className='suceess-data'>
                                                    <h3>INDMoney requested data from HDFC Bank</h3>
                                                    <p>Requested on: 04 Mar 2023, 04:59 PM</p>
                                                </div>
                                                <div className='suceess-icon-box'>
                                                    <i className="fa fa-check-circle" aria-hidden="true" style={{ color: '#31a34c' }}></i>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='suceess-img-box'>
                                                    <div className='sucess-imgbox-inner'>
                                                        <img src={reqdataimg} alt='icon' />
                                                    </div>
                                                </div>
                                                <div className='suceess-data'>
                                                    <h3>Data received from HDFC Bank</h3>

                                                </div>
                                                <div className='suceess-icon-box'>
                                                    <i className="fa fa-check-circle" aria-hidden="true" style={{ color: '#31a34c' }}></i>
                                                </div>
                                            </li>
                                        </ul>

                                        <p className='update-msg'>Your balances will be updated within next 15 mins!</p>
                                    </div>
                                </div>

                            </div>
                            <div className='om-notfindac'>
                                <p className='om-ac-warning'>90% of users get their data in 60 seconds!</p>
                                <a href="" className='om-btn'>Continue</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Successful;


