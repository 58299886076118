import hdfcimg from '../../assets/images/hdfc.svg'
import cellimg from '../../assets/images/cell-phone.png'
import backarrowimg from '../../assets/images/back-arrow.svg'
import logo from '../../assets/images/logo.svg'
import { useEffect, useState } from 'react'
import WebService from '../../utility/WebService'
import { useLocation } from "react-router-dom";
import HelperService from '../../utility/HelperService'

const LinkAccount = () => {
    let location = useLocation();
    const [accounts, setAccounts] = useState<any[]>([])

    useEffect(() => {
        if (location.state != null) {
            // getAccounts(location.state.key)
            setAccounts(location.state.data)
        }
    }, [location])

    // const getAccounts = (value: any) => {
    //     WebService.getAPI({
    //         action: `/aaclient/accounts/search/${value}`,
    //         body: null
    //     })
    //         .then((res: any) => {
    //             for (var i in res.accounts) {
    //                 res.accounts[i].isChecked = false;
    //             }
    //             setAccounts(res.accounts)
    //         })
    //         .catch((e) => {
    //             // toast.error(e.response.data.errorMsg)
    //         })
    // }

    const onSelect = (index: any) => {
        for (var i in accounts) {
            if (index == i) {
                accounts[i].isChecked = !accounts[i].isChecked
            }
        }
        setAccounts([...accounts])
    }
    return (
        <>
            <section className="mt100 om-linkac">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a href="Select Account" className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Link Account</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-4">
                            <h4 className="d-none d-sm-block">Link Bank Account</h4>
                            <p className="om-found-text">We found this bank account linked to your mobile number +91-9850894626. Please select the account you want to link.</p>
                            <div className='om-link-wraaper'>
                                <div className="row">
                                    {
                                        accounts?.length > 0 && accounts?.map((res, index) => {
                                            return (
                                                <div className='col-sm-12' onClick={() => onSelect(index)}>
                                                    <div className={res.isChecked == false ? 'om-slect-box ' : 'om-slect-box om-slect-box-selected'}>
                                                        <div className="om-link-box">
                                                            <div><img src={location.state && location.state.logo} alt="icon" height={40} width={40} /></div>
                                                            <div>
                                                                <h5>{res.accType && HelperService.getTitleCase(res.accType)} Account</h5>
                                                                <h6>{res.maskedAccNumber}</h6>
                                                                <p>{location.state && location.state.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className='om-link-box-rt text-right'>
                                                            <i className="fa fa-check-circle" style={{ color: res.isChecked && '#1542b0' }} aria-hidden="true"></i>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='col-lg-6 col-sm-6 mx-auto my-auto pt-4 position-fixed fixed-bottom'>
                                    <div className='om-notfindac'>
                                        <h3>Couldn't find your account?</h3>
                                        <div className='d-flex om-warning-text'>
                                            <span><img className='mr-2' src={cellimg} alt="icon" /></span>
                                            <div>
                                                <p>In case you have a savings account linked to secondary mobile no. Please Modify your mobile number </p>
                                                <p><a href="/modify-mobilenum">Modify mobile number</a></p>
                                            </div>
                                        </div>

                                        <p className='om-ac-warning'>We currently don't support joint and current accounts.</p>
                                        <a href="/fetch-detail" className='om-btn'>Proceed</a>
                                        <p className='om-copyright-text'>Powered by RBI regulated Account Aggregator <img src={logo} alt="" width="30" height="30" /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LinkAccount;