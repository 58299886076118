import logo from '../../assets/images/logo.svg';
import mobileIcon from '../../assets/images/phone-icon.svg';
import userIcon from '../../assets/images/user-icon.svg';
import atIcon from '../../assets/images/at-icon.svg';
import lockIcon from '../../assets/images/lock-icon.svg';
import eyeIcon from '../../assets/images/eye-icon.svg';
import { useForm } from "react-hook-form";
// import { Label } from '../../components/Label/Label';
import HelperService from '../../utility/HelperService';
import axios from 'axios';
import { BASE_URL } from '../../utility/WebService';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
// import Loader from '../../components/Loader/Loader';
import { useEffect, useState } from 'react';

const Signup = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
    const watchAllFields = watch();
    let history = useNavigate();
    let location = useLocation();
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (location.state != null) {
            setValue('mobile', location.state.mobile)
        }
    }, [])

    const onSignup = (data: any) => {
        if (watchAllFields.confirm == false) {
            toast.error('Please accept terms & condition')
        } else {
            setLoading(true)
            const headers = {
                'Content-Type': 'application/json',
                'appLang': 'en_US'
            }
            axios.post(`${BASE_URL}/aaclient/signup`, data, {
                headers: headers
            })
                .then((res) => {
                    setLoading(false)
                    history("/verify-otp", { state: { mobile: data.mobile } });
                })
                .catch((e) => {
                    setLoading(false)
                    toast.error(e.response.data.errorMsg)
                })
        }
    }

    return (
        <>
            {/* <Loader show={isLoading} /> */}
            <section className="mt100">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-12 col-sm-12">
                            {/* <header className="d-none d-sm-block">
                                <nav className="navbar navbar-expand-md navbar-dark fixed-top">
                                    <div className="container">
                                        <a className="navbar-brand" href="index.php">
                                            <img src={logo} alt="" width="70" height="70" />
                                        </a>
                                    </div>
                                </nav>
                            </header> */}
                            <div className="white-bg d-block d-sm-none">
                                <span>Sign Up</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block my-4">Sign Up</h4>
                            <form className="mt-3" onSubmit={handleSubmit(onSignup)}>
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src={mobileIcon} alt="Phone" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" minLength={10} maxLength={10} placeholder="Mobile Number" {...register("mobile", { required: true, maxLength: 10, minLength: 10 })}
                                            onKeyPress={(e) =>
                                                HelperService.allowOnlyNumericValue(e)
                                            } />
                                    </div>
                                    {errors.mobile && (
                                        <div style={{ marginLeft: 60 }}>
                                            {/* <Label title={"Please enter mobile number"} modeError={true} /> */}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Name</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src={userIcon} alt="User" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter Name" {...register("name", { required: true })} />
                                    </div>
                                    {errors.name && (
                                        <div style={{ marginLeft: 60 }}>
                                            {/* <Label title={"Please enter name"} modeError={true} /> */}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Enter PIN</label>
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src={lockIcon} alt="lock" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter PIN" {...register("pin", { required: true })}
                                            onKeyPress={(e) =>
                                                HelperService.allowOnlyNumericValue(e)
                                            } />
                                    </div>
                                    {errors.pin && (
                                        <div style={{ marginLeft: 60 }}>
                                            {/* <Label title={"Please enter valid pin"} modeError={true} /> */}
                                        </div>
                                    )}
                                    <div className="input-group input-group-lg mt-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src={eyeIcon} alt="lock" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter PIN" {...register("confirm_pin", { required: true })}
                                            onKeyPress={(e) =>
                                                HelperService.allowOnlyNumericValue(e)
                                            } />
                                    </div>
                                    {(errors.confirm_pin || watchAllFields?.pin != watchAllFields?.confirm_pin) && (
                                        <div style={{ marginLeft: 60 }}>
                                            {/* <Label title={"Pin does not match"} modeError={true} /> */}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group d-flex">
                                    <input type="checkbox" {...register("confirm", { required: false })} />&nbsp; <span style={{ fontSize: 14, marginTop: 12 }}> I agree to <a href="#" className="tc">Terms & conditions</a></span>
                                </div>
                                <div className="form-group mt-4">
                                    <button type="submit" name="" value="Create Account" className="btns" >Create Account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup;